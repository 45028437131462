import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

import css from "./hero.module.scss"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={css.hero}>
      <div className={css.hero__overlay}></div>
      <Img
        className={css.hero__img}
        fluid={data.heroImage.childImageSharp.fluid}
      />
      <div className={css.hero__content}>
        <h1 className={css.hero__title}>Blend-Divine</h1>
        <p className={css.hero__desc}>
          An <strong>accelerator</strong> and <strong>incubator</strong>, focusing on <strong>Product and Services</strong> related
          to <strong>Wholesale E-Commerce</strong> Industry
        </p>
        <Link to="/about-us" className={css.hero__cta}>
          Know More
        </Link>
      </div>
    </div>
  )
}

export default Hero
