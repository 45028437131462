import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/Layout/layout"
// import Image from "../components/image"
import SEO from "../components/SEO/seo"
import Hero from "../components/Hero/hero"
import ArticleFullWidth from "../components/ArticleFullWidth/article-full-width"
// import ServicesBrief from "../components/ServicesBrief/services-brief"
import Banner from "../components/Banner/banner"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <ArticleFullWidth
      title="Why Blend-Divine?"
      descriptions={[
        `Blend- Divine is a pioneer name in Global E-commerce industry. We have been in a export of products & Services through E-commerce since the boom of E-commerce portals. We have We have helped thousands of entrepreneurs to launch their businesses online. Blend-Divine allows you to choose the best products to sell from thousands of dropshipping suppliers all over the world along with the know-how of online selling techniques.`,
      ]}
      ctaText="Know More"
      ctaLink="/about-us"
    />
    {/* <ServicesBrief /> */}
    <Banner/>
    <ArticleFullWidth
      title="Our Mission"
      descriptions={[
        `Blend-Divine’s mission is to contribute to the development of Businesses & Entrepreneurs through Research based Borderless e-Commerce Products & Services and to generate livelihood opportunities to Millions.`,
      ]}
    />
  </Layout>
)

export default IndexPage
